import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Sites built with the Carbon Gatsby theme starter come ready to go with some
helpful npm scripts. You can run these commands by typing `}<inlineCode parentName="p">{`yarn [command]`}</inlineCode>{` or
`}<inlineCode parentName="p">{`npm run [command]`}</inlineCode></p>
    </PageDescription>
    <h2>{`Clean`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`clean`}</strong>{` - this is used to clean gatsby’s cache and public directory. It
should be used sparingly, only when you’re experiencing weird errors or
caching issues.`}</li>
    </ul>
    <h2>{`Develop`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`dev`}</strong>{` - this is your primary for starting up your gatsby site for
development`}</li>
      <li parentName="ul"><strong parentName="li">{`dev:clean`}</strong>{` - this is provided as a convenience; it first runs `}<inlineCode parentName="li">{`clean`}</inlineCode>{` then
`}<inlineCode parentName="li">{`dev`}</inlineCode></li>
    </ul>
    <h2>{`Build`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`build`}</strong>{` - this is what you’ll use to bundle your site. Gatsby will minimize
your images and create a static, blazing fast site in your `}<inlineCode parentName="li">{`public`}</inlineCode>{` directory`}</li>
      <li parentName="ul"><strong parentName="li">{`serve`}</strong>{` - this can be run to serve the bundle locally after running `}<inlineCode parentName="li">{`build`}</inlineCode>{`.
It can be used to debug locally if any issues are encountered durring build.`}</li>
      <li parentName="ul"><strong parentName="li">{`build:clean`}</strong>{` - this is a provided as a convenience, it first runs `}<inlineCode parentName="li">{`clean`}</inlineCode>{`
then `}<inlineCode parentName="li">{`build`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      